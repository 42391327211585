
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import Rules from "@/app/modules/_global/classes/Rules";
import moment from "moment";

import DatePicker from "@/app/modules/_global/components/elements/DatePicker.vue";

@Component({
  components: {
    Alert: () => import("@/app/modules/_global/components/Alert.vue"),
    datepicker_: DatePicker
  },

  filters: {
    parse_weekday(value: number) {
      return moment.weekdaysMin(value);
    }
  }
})
export default class BlockFormComponent extends Vue {
  @VModel({ type: Object }) formData!: object;
  @Prop(Boolean) readonly readonly?: boolean;
  @Prop(Object) readonly errors?: object;

  valid = true;
  dateMin = moment().format("YYYY-MM-DD");

  rules = {
    required: Rules.required,
    date: Rules.date
  };
}
